import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'd3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { faStar as faStarOpen } from '@fortawesome/pro-regular-svg-icons';
import './Metadata.scss';

function Metadata(props) {
  const { name, population, confidence, analysisPeriod, selectedArea, updateSelectedArea, analysisCondition, } = props;
  const popTotal = (population || []).filter(d => (!selectedArea && d.aag_id === 0) || d.aar_id === selectedArea)
    .map((d) => {
      const { phase3, phase4, phase5, } = d;
      let t = 0;
      if (phase3) t += phase3.population;
      if (phase4) t += phase4.population;
      if (phase5) t += phase5.population;
      return t;
    })
    .reduce((sum, pop) => sum + pop, 0);
  
  const formattedPop = format('.3~s')(popTotal);
  const projected = analysisPeriod !== 'C';
  const curProjPrefix = analysisPeriod === 'A' ? '2nd Projected': ( analysisPeriod ==='P' ? 'Projected' : 'Current');
  const crisisText= analysisCondition === 'C' ? 'people Level 3 and higher' : 'people in crisis or worse';

  return (
    <div className={`metadata${projected ? ' metadata--projected' : ''}`}>
      <div className="metadata__main">
        <div className={`metadata__name ${analysisCondition === 'C' ? 'metadata__name--chronic' : ''}`}>{name}</div>
        <div className={`metadata__population ${analysisCondition === 'C' ? 'metadata__population--chronic' : ''}`}>
          {curProjPrefix}: &nbsp;<span>{formattedPop}</span>{crisisText}
        </div>
      </div>
      <div className="metadata__bottom">
        <div className="metadata__confidence">
          <span>Evidence Level of Analysis:</span>
          <FontAwesomeIcon icon={confidence >= 1 ? faStar : faStarOpen} />
          <FontAwesomeIcon icon={confidence >= 2 ? faStar : faStarOpen} />
          <FontAwesomeIcon icon={confidence > 2 ? faStar : faStarOpen} />
        </div>
        {selectedArea && (
          <div className="metadata__reset" onClick={() => { updateSelectedArea(null); }}>
            <FontAwesomeIcon icon={faChevronLeft} />
            Return to Country
          </div>
        )}
      </div>
    </div>
  );
}

Metadata.defaultProps = {
  name: '',
  population: [],
  confidence: 0,
  analysisPeriod: 'C',
  analysisCondition: '',
  selectedArea: null,
  updateSelectedArea: () => {},
};

Metadata.propTypes = {
  name: PropTypes.string,
  population: PropTypes.arrayOf(PropTypes.object),
  confidence: PropTypes.number,
  analysisPeriod: PropTypes.string,
  analysisCondition: PropTypes.string,
  selectedArea: PropTypes.string,
  updateSelectedArea: PropTypes.func,
};

export default Metadata;
