import React from 'react';
import PropTypes from 'prop-types';
import DataContext from '../../../DataContext/DataContext';
import Exportable from '../Exportable/Exportable';
import PopulationRow from './PopulationRow/PopulationRow';
import './PopulationEstimates.scss';

class PopulationEstimates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closedGroups: [0],
      populationTableChanged: false,
    };
    this.onGroupClick = this.onGroupClick.bind(this);
    this.resizeIFrame = this.resizeIFrame.bind(this);
  }

  resizeIFrame () {
    const { populationTableChanged } = this.state;
    this.setState({ populationTableChanged : !populationTableChanged });
    const { resizeIFrame } = this.props;
    setTimeout(() => {
      // there are some changes with the population estimates, set the table size for resizing an iframe
      const contentContainerList = document.getElementsByClassName('population-estimates-table');
      let rHeight = 0;
      for (let i = 0; i < contentContainerList.length; i += 1) {
        const wHeight = contentContainerList[i].getBoundingClientRect().height;
        if (wHeight > rHeight) {
          rHeight = wHeight;
        }
      }
      resizeIFrame(rHeight);
    }, 1000);
  }
  
  onGroupClick(area) {
    const groupId = +area.aag_id;
    const { closedGroups } = this.state;
    const idIndex = closedGroups.indexOf(groupId);

    if (idIndex === -1) {
      this.setState({
        closedGroups: [...closedGroups, groupId],
      });
    } else {
      this.setState({
        closedGroups: [...closedGroups.slice(0, idIndex), ...closedGroups.slice(idIndex + 1)],
      });
    }
    // this.resizeIFrame();
  }

  getRow(d, groups) {
    const {
      selectedArea,
      highlightedAreas,
      updateHighlightedAreas,
      updateSelectedArea,
      areasGeo,
    } = this.context;
    const { analysisCondition } = this.props;
    const {
      closedGroups,
    } = this.state;

    const isClosed = closedGroups.includes(+d.aag_id);
    const allClosed = closedGroups.includes(0);
    const newIds = areasGeo.features.filter(aar => aar.properties.aar_id === d.aar_id)
      .map(aar => aar.properties)[0];

    if (newIds) {
      if ((newIds.use_ptu_for_pt && newIds.layer_type === 'aar') || (!newIds.use_ptu_for_pt && newIds.layer_type === 'pun') ) return null;
    }

    let isSelected = false;
    let isFaded = false;
    const isHighlighted = highlightedAreas.includes(d.aar_id);
    if (selectedArea !== null) {
      if (selectedArea === d.aar_id) {
        isSelected = true;
      } else {
        isFaded = true;
      }
    }
    let level;
    let key = d.aag_id;

    if (key !== null && +key === 0) {
      level = 'level-0';
    } else if ('aar_id' in d) {
      key = `${key || ''}-${d.aar_id}`;
      if (d.aag_id !== null && groups.includes(d.aag_id)) {
        level = 'level-2-sub';
      } else {
        level = 'level-2';
      }
    } else {
      level = 'level-1';
    }
    
    if (
      (level !== 'level-1' && level !== 'level-0' && isClosed) ||
      ((level === 'level-2' || level === 'level-2-sub') &&
        d.view_level &&
        d.view_level.toLowerCase() === 'group') ||
      (level !== 'level-0' && allClosed)
    )
      return null;
    
    return (
      <PopulationRow
        key={key.toString()}
        area={d}
        {...{
          isClosed,
          level,
          isSelected,
          isFaded,
          isHighlighted,
          updateHighlightedAreas,
          updateSelectedArea,
          onGroupClick: this.onGroupClick,
          analysisCondition,
        }}
      />
    );
  }

  static getPopulationByArea({ population }) {
    const ids = [...new Set(population.map(d => d.aar_id))];

    return ids.map((id) => {
      const record = {
        aar_id: Number(id),
      };
      const phases = population.filter(d => d.aar_id === id);
      phases.forEach((d) => {
        record[`phase${d.phase}`] = d;
      });

      return record;
    });
  }

  getRows() {
    const { population } = this.props;

    if (population === null) return <tr />;

    const groups = population.filter(d => d.aag_id !== null && d.children !== undefined )
      .map(d => d.aag_id);

    return population.map(d => { 
      // dont show areas with inadquate evidence
      return (!(d.hasOwnProperty('overall_phase') && d.overall_phase === 9)) && this.getRow(d, groups);
    });
  }

  render() {
    const { analysisPeriod, split, analysisCondition } = this.props;
    const projected = analysisPeriod !== 'C';
    const analysisConditionPrefix = (analysisCondition === 'C') ? 'chronic-' : '';
    const analysisPhaseLabel = (analysisCondition === 'C') ? 'Level' : 'Phase';
    return (
      <Exportable className={`population-estimates${split ? ' split' : ''}`} suffix={projected ? '_projected' : ''} name="population estimates graphic" exportFull>
        <div className="population-estimates-table">
          <table className="pop-table">
            <tbody>
              <tr>
                <th rowSpan="2" className="pop-table__name-col"><div>Name</div></th>
                <th rowSpan="2" className="pop-table__area-phase-col area-phase">Area Phase</th>
                <th rowSpan="2" className="pop-table__area-total-col">Total # (pp)</th>
                <th colSpan="2" className={`${analysisConditionPrefix}phase-1-col`}>{analysisPhaseLabel} 1</th>
                <th colSpan="2" className={`${analysisConditionPrefix}phase-2-col`}>{analysisPhaseLabel} 2</th>
                <th colSpan="2" className={`${analysisConditionPrefix}phase-3-col`}>{analysisPhaseLabel} 3</th>
                <th colSpan="2" className={`${analysisConditionPrefix}phase-4-col`}>{analysisPhaseLabel} 4</th>
                { analysisCondition !== 'C' &&
                  <th colSpan="2" className={`${analysisConditionPrefix}phase-5-col`}>{analysisPhaseLabel} 5</th>
                }
                <th colSpan="2">{analysisCondition === 'C' ? 'L3+': 'P3+'}</th>
              </tr>
              <tr>
                <th className={`${analysisConditionPrefix}phase-1-col`}>#</th>
                <th className={`${analysisConditionPrefix}phase-1-col`}>%</th>
                <th className={`${analysisConditionPrefix}phase-2-col`}>#</th>
                <th className={`${analysisConditionPrefix}phase-2-col`}>%</th>
                <th className={`${analysisConditionPrefix}phase-3-col`}>#</th>
                <th className={`${analysisConditionPrefix}phase-3-col`}>%</th>
                <th className={`${analysisConditionPrefix}phase-4-col`}>#</th>
                <th className={`${analysisConditionPrefix}phase-4-col`}>%</th>
                { analysisCondition !== 'C' &&
                  <React.Fragment>
                    <th className={`${analysisConditionPrefix}phase-5-col`}>#</th>
                    <th className={`${analysisConditionPrefix}phase-5-col`}>%</th>
                  </React.Fragment>
                }
                <th>#</th>
                <th>%</th>
              </tr>
              {this.getRows()}
            </tbody>
          </table>
        </div>
      </Exportable>
    );
  }
}

PopulationEstimates.defaultProps = {
  population: null,
  analysisPeriod: 'C',
  analysisCondition: 'A',
};

PopulationEstimates.propTypes = {
  population: PropTypes.arrayOf(PropTypes.object),
  analysisPeriod: PropTypes.string,
  analysisCondition: PropTypes.string,
  resizeIFrame: PropTypes.func,
};

PopulationEstimates.contextType = DataContext;

export default PopulationEstimates;
